export default [
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/calendar",
    name: "Calendar",
  },
  {
    path: "/rooms",
    name: "Our rooms",
  },
  {
    path: "/reviews",
    name: "Reviews",
  },
  {
    path: "/about",
    name: "About us",
  },
  {
    path: "/contact",
    name: "Bookings",
  },
];
