// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-js": () => import("./../../../src/pages/license.js" /* webpackChunkName: "component---src-pages-license-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-templates-booking-booking-complete-js": () => import("./../../../src/templates/booking/booking-complete.js" /* webpackChunkName: "component---src-templates-booking-booking-complete-js" */),
  "component---src-templates-booking-event-contact-js": () => import("./../../../src/templates/booking/event-contact.js" /* webpackChunkName: "component---src-templates-booking-event-contact-js" */),
  "component---src-templates-booking-event-details-js": () => import("./../../../src/templates/booking/event-details.js" /* webpackChunkName: "component---src-templates-booking-event-details-js" */),
  "component---src-templates-booking-event-facilities-js": () => import("./../../../src/templates/booking/event-facilities.js" /* webpackChunkName: "component---src-templates-booking-event-facilities-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-room-book-template-js": () => import("./../../../src/templates/room-book-template.js" /* webpackChunkName: "component---src-templates-room-book-template-js" */),
  "component---src-templates-room-template-js": () => import("./../../../src/templates/room-template.js" /* webpackChunkName: "component---src-templates-room-template-js" */)
}

