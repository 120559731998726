import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  footerImages,
  footerLinks,
  footerInfo,
  footer,
  footerWrapper,
} from "../css/footer.module.css";

const getSiteFooterImages = graphql`
  query FooterQuery {
    allStrapiFooterImages {
      edges {
        node {
          id
          footerImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiPrivacy {
      privacyPolicyURL
    }
  }
`;

const Footer = ({ title }) => {
  const data = useStaticQuery(getSiteFooterImages);

  return (
    <div className={footerWrapper}>
      <footer className={footer}>
        <div className={footerInfo}>
          <h3>{title}</h3>
          <div className={footerLinks}>
            <ul>
              <li>
                Want to stay in touch?{" "}
                <Link to="/about/#mailing-list-subscribe">
                  Subscribe to our mailing list
                </Link>{" "}
                to hear about events – you can opt out at any time.
              </li>
              <li></li>
            </ul>
            <ul>
              <li>
                <a href="https://beta.charitycommission.gov.uk/charity-details/?regid=1055737&subid=0">
                  Charity number 1055737
                </a>
              </li>
              <li>
                <a href={data.strapiPrivacy.privacyPolicyURL}>Privacy policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={footerImages}>
          {data.allStrapiFooterImages.edges.map((item, _i) => (
            <div key={item.node.id}>
              <GatsbyImage
                image={item.node.footerImage.childImageSharp.gatsbyImageData}
                alt={item.node.id}
                imgStyle={{ objectFit: "cover" }}
                style={{ height: "48px", width: "150px" }}
              />
            </div>
          ))}
        </div>
      </footer>
    </div>
  );
};
export default Footer;
