import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { BookingContextProvider } from "../contexts/BookingContext";

import "../css/layout.css";

import Navigation from "./navigation";
import Footer from "./footer";

const getSiteDetails = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(getSiteDetails);

  return (
    <BookingContextProvider>
      <Helmet htmlAttributes={{ lang: "en-GB" }} />
      <main>
        <Navigation title={data.site.siteMetadata.title} />
        {children}
        <Footer title={data.site.siteMetadata.title} />
      </main>
    </BookingContextProvider>
  );
};

export default Layout;
