import React, { useState } from "react";
import { Link } from "gatsby";
import { FaAlignRight } from "react-icons/fa";

import {
  navLinks,
  showNav,
  logoIcon,
  logoBtn,
  navHeader,
  navCenter,
  navbar,
} from "../css/navigation.module.css";
import logo from "../images/PVH_poppy-01.png";
import links from "../constants/links";

const NavigationBar = ({ title }) => {
  const [isOpen, setNav] = useState(false);

  const toggleNav = () => {
    setNav((isOpen) => !isOpen);
  };

  return (
    <nav className={navbar}>
      <div className={navCenter}>
        <div className={navHeader}>
          <img src={logo} alt={""} width="50" />
          <h1>{title}</h1>
          <button type="button" className={logoBtn} onClick={toggleNav}>
            <FaAlignRight className={logoIcon} />
          </button>
        </div>
        <ul className={isOpen ? `${navLinks} ${showNav}` : `${navLinks}`}>
          {links.map((link, i) => {
            return (
              <li key={i}>
                <Link to={link.path}>{link.name}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;
